import "styles/globals.css";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { useEffect, useState, FunctionComponent } from "react";
import { useRouter } from "next/router";
import type { AppProps } from "next/app";
import CssBaseline from "@mui/material/CssBaseline";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import createEmotionCache from "utility/createEmotionCache";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ErrorBoundary from "components/ErrorBoundary.js";

import { AppStateProvider } from "components/mainPage/state/state";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import Head from "next/head";
import MuiXLicense from "MuiXLicense";

import { SpeedInsights } from "@vercel/speed-insights/next";
import { Analytics } from "@vercel/analytics/react";

import DatadogInit from "datadog-init";

import { LocalStorageProvider } from "../contexts/LocalStorageContext";
import GlobalCssPriority from "../components/GlobalCssPriority";
// Set the default timezone to UTC
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(utc);

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();

const MyApp: FunctionComponent<MyAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [queryClient] = useState(() => new QueryClient());
  const router = useRouter();

  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };

  const userId = localStorage()?.userId;
  const full_name = localStorage()?.fullname;

  useEffect(() => {
    // Handle route changes
    const handleRouteChange = (url) => {
      window.analytics.page(url, {
        userId: userId,
        full_name: full_name,
        email: localStorage()?.email,
        // ... other properties
      });
    };

    // Add the event listener when the component is mounted
    router.events.on("routeChangeComplete", handleRouteChange);

    // Remove the event listener when the component is unmounted
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, userId, full_name]);

  return (
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <AppStateProvider>
            <CacheProvider value={emotionCache}>
              <GlobalCssPriority>
                <CssBaseline />
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                  <title>SocialCrowd</title>
                </Head>
                <Analytics />
                <SpeedInsights />
                <DatadogInit />
                <LocalStorageProvider>
                  <ErrorBoundary>
                    <MuiXLicense />

                    <Component
                      {...pageProps}
                      key={!router.isReady && router.asPath}
                    />
                  </ErrorBoundary>
                </LocalStorageProvider>
              </GlobalCssPriority>
            </CacheProvider>
        </AppStateProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </UserProvider>
  );
};

export default MyApp;
